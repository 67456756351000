import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNameList } from 'country-list';
import Input from '../../../ui/Formik/Input';
import TextAreaLinkify from './TextAreaLinkify';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';
import { currencyDataRequest } from '../../../redux/reducers/currency/reducer';
import { getWalletsRequest } from '../../../redux/reducers/wallets/reducer';
import { getBankAccounts } from '../../../redux/reducers/bankAccounts/selectors';
import { getFiatCurrencyData } from '../../../redux/reducers/currency/selectors';
import CountriesSelect from '../../../ui/Formik/Select/CountriesSelect';
import InputPattern from '../../../ui/Formik/Input/inputPattern';
import BankCurrencySelect from '../../../ui/Formik/Select/BankCurrencySelect';
import InputFile from '../../../ui/Formik/InputFile';
import { createFiatDetailsRequest } from '../../../redux/reducers/detailedView/reducer';
import { capitalizeWords } from '../../../services/utils/strings';

export interface IBankAccountForm {
	id: number | null;
	closeForm: () => void;
}

export interface ICountry {
	value: string;
	label: string;
}

interface ICurrency {
	value: string;
	label: string;
	name: string;
	id: number | string;
}

const DetailedViewWithdrawalFiatForm = ({ formData }: { formData: any }) => {
	const dispatch = useDispatch();
	const bankAccounts = useSelector(getBankAccounts);
	const fiatCurrencyData = useSelector(getFiatCurrencyData);
	// console.log(fiatCurrencyData);
	const [countries, setCountries] = useState<[] | ICountry[]>([]);
	const [currencies, setCurrencies] = useState<[] | ICurrency[]>([]);
	const [selectedBeneficiaryCountry, setSelectedBeneficiaryCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	const [selectedBankCountry, setSelectedBankCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	const [selectedCurrency, setSelectedCurrency] = useState<ICurrency>({
		value: '',
		label: '',
		name: '',
		id: '',
	});
	const [resetCustomSelect, setResetCustomSelect] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [fileList, setFileList] = useState<any>([]);

	useEffect(() => {
		const newArr: ICurrency[] = [];
		fiatCurrencyData.forEach((item) => {
			newArr.push({
				value: item.name,
				label: item.code,
				name: item.name,
				id: item.id,
			});
		});
		setCurrencies(newArr);
	}, [fiatCurrencyData]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		/* fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code')
			.then((response) => response.json())
			.then((data) => {
				setCountries(data.countries);
			}); */
		const result = Object.entries(getNameList()).map(([key, value]) => ({
			label: capitalizeWords(key),
			value,
		}));
		setCountries(result);
	}, []);

	const initialValues = {
		admin_notes: '',
		client_notes: formData.client_notes ? formData.client_notes : '',
		upload_file: '',
		notes: '',
		/* description:
			formData.description || formData?.bank?.account_number
				? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				  `${formData?.bank?.bank_name} ${formData?.bank?.account_number}`
				: '', */
		description:
			formData.description ||
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${formData?.bank?.bank_name} ${formData?.bank?.account_number}` ||
			'',
		reference: formData.reference || formData?.tx_hash || '',
		bank_nickname: '',
		beneficiary_name: '',
		beneficiary_address: '',
		beneficiary_country: '',
		bank_currency: '',
		bank_name: '',
		bank_address: '',
		bank_country: '',
		swift_bic: '',
		account_number: '',
		note: '',
		payment_notes: '',
	};
	const [initialInputValues, setInitialInputValues] = useState<any>(initialValues);

	useEffect(() => {
		setInitialInputValues((prevState: any) => ({
			...prevState,
			...{ client_notes: formData.client_notes ? formData.client_notes : '' },
		}));
	}, [formData.client_notes]);

	useEffect(() => {
		if (formData.bank_account && countries && fiatCurrencyData) {
			const bankAccount = formData.bank_account;
			// console.log(bankAccount[0]?.bank_currency);
			setInitialInputValues((prevState: any) => ({ ...prevState, ...bankAccount }));
			const beneficiaryCountry = countries.find(
				(item) => item.value === bankAccount.beneficiary_country,
			);
			const bankCountry = countries?.find((item) => item.value === bankAccount.bank_country);
			const bankCurrency = fiatCurrencyData.filter((el) => el.id === bankAccount.bank_currency);
			beneficiaryCountry && setSelectedBeneficiaryCountry(beneficiaryCountry);
			bankCountry && setSelectedBankCountry(bankCountry);
			if (bankCurrency && bankCurrency.length) {
				setSelectedCurrency({
					value: bankCurrency[0].name,
					label: bankCurrency[0].code,
					name: bankCurrency[0].name,
					id: bankCurrency[0].id,
				});
			}
		}
	}, [countries, fiatCurrencyData, formData.bank_account]);

	const validationSchema = yup.object().shape({
		client_notes: yup.string().max(255, 'Maximum length is 255 characters'),
	});

	useEffect(() => {
		dispatch(currencyDataRequest());
		dispatch(getWalletsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="detailed-view-form">
			<Formik
				initialValues={initialInputValues}
				validationSchema={validationSchema}
				onSubmit={(value, { resetForm, setSubmitting }) => {
					setSubmitting(true);
					const formDataRequest = new FormData();
					fileList &&
						fileList.length &&
						fileList.forEach((file: File) => formDataRequest.append(`files[]`, file));
					const requestBody = {
						id: formData.id,
						client_notes: value.client_notes,
					};
					Object.keys(requestBody).forEach((key) => {
						const valueRequest = requestBody[key as keyof typeof requestBody];
						if (valueRequest !== undefined) {
							formDataRequest.append(key, valueRequest.toString());
						}
					});
					dispatch(
						createFiatDetailsRequest({
							id: formData.id,
							body: formDataRequest,
						}),
					);
					setFileList([]);
					resetForm();
					setSubmitting(false);
				}}
				enableReinitialize
				validateOnBlur
			>
				{({
					setFieldTouched,
					touched,
					values,
					errors,
					isValid,
					dirty,
					setFieldValue,
					isSubmitting,
				}) => (
					<>
						<Form>
							{/* <div className="input">
								<Field title="Notes" name="notes" component={TextAreaLinkify} />
							</div> */}

							<div className="input-wrap input-wrap--col-2">
								<Field
									title="Description"
									type="text"
									placeholder=""
									name="description"
									component={Input}
									disabled
								/>
								<Field
									title="Reference"
									type="text"
									placeholder=""
									name="reference"
									component={Input}
									disabled
								/>
							</div>

							<div className="input-wrap input-wrap--col-2">
								<Field
									title="Bank Nickname"
									type="text"
									placeholder="Enter Bank Nickname"
									name="bank_nickname"
									required
									component={Input}
									onBlur={() => setFieldTouched('bank_nickname')}
									disabled
								/>
								<Field
									title="Beneficiary Name"
									type="text"
									placeholder="Enter Beneficiary Name"
									name="beneficiary_name"
									required
									component={Input}
									onBlur={() => setFieldTouched('beneficiary_name')}
									disabled
								/>
							</div>
							<Field
								title="Beneficiary Address"
								type="text"
								placeholder="Enter Beneficiary Address"
								name="beneficiary_address"
								required
								component={Input}
								onBlur={() => setFieldTouched('beneficiary_address')}
								disabled
							/>
							<div className="input">
								<Field
									title="Beneficiary Country"
									type="text"
									placeholder="Select Country"
									searchField
									name="beneficiary_country"
									component={CountriesSelect}
									arr={countries}
									onChange={setSelectedBeneficiaryCountry}
									setTouched={() => setFieldTouched('beneficiary_country')}
									touched={touched.beneficiary_country}
									resetCustomSelect={resetCustomSelect}
									setResetCustomSelect={setResetCustomSelect}
									activeValue={selectedBeneficiaryCountry || undefined}
									isDisabled
								/>
								{touched.beneficiary_country && !values.beneficiary_country && (
									<div className="input-notify input-notify--absolute">
										<span className="input-notify__char">*</span>
										<span className="input-notify__text">{errors.beneficiary_country}</span>
									</div>
								)}
							</div>
							<div className="input-wrap input-wrap--col-2">
								<Field
									title="Bank Name"
									type="text"
									placeholder="Enter Bank Name"
									name="bank_name"
									required
									component={Input}
									onBlur={() => setFieldTouched('bank_name')}
									disabled
								/>
								<Field
									title="SWIFT / BIC"
									type="text"
									placeholder="Enter SWIFT / BIC"
									name="swift_bic"
									required
									component={InputPattern}
									onBlur={() => setFieldTouched('swift_bic')}
									disabled
								/>
							</div>
							<Field
								title="Bank Address"
								type="text"
								placeholder="Enter Bank Address"
								name="bank_address"
								required
								component={Input}
								onBlur={() => setFieldTouched('bank_address')}
								disabled
							/>
							<div className="input-wrap input-wrap--col-2">
								<div className="input mb-0">
									<Field
										title="Bank Country"
										type="text"
										placeholder="Select Country"
										dropdownTitle="Select Country"
										name="bank_country"
										searchField
										component={CountriesSelect}
										arr={countries}
										setTouched={() => setFieldTouched('bank_country')}
										touched={touched.bank_country}
										onChange={setSelectedBankCountry}
										resetCustomSelect={resetCustomSelect}
										setResetCustomSelect={setResetCustomSelect}
										activeValue={selectedBankCountry || undefined}
										isDisabled
									/>
									{touched.bank_country && !values.bank_country && (
										<div className="input-notify input-notify--absolute">
											<span className="input-notify__char">*</span>
											<span className="input-notify__text">{errors.bank_country}</span>
										</div>
									)}
								</div>

								<div className="input mb-0">
									<Field
										title="Currency"
										type="text"
										placeholder="Select Currency"
										dropdownTitle="Select Currency"
										name="bank_currency"
										searchField
										component={BankCurrencySelect}
										arr={currencies}
										setTouched={() => setFieldTouched('bank_currency')}
										touched={touched.bank_currency}
										onChange={setSelectedCurrency}
										resetCustomSelect={resetCustomSelect}
										setResetCustomSelect={setResetCustomSelect}
										activeValue={selectedCurrency || undefined}
										isDisabled
									/>
									{touched.bank_currency && !values.bank_currency && (
										<div className="input-notify input-notify--absolute">
											<span className="input-notify__char">*</span>
											<span className="input-notify__text">{errors.bank_currency}</span>
										</div>
									)}
								</div>
							</div>
							<Field
								title="Account Number"
								type="text"
								placeholder="Enter Account Number"
								name="account_number"
								required
								component={Input}
								onBlur={() => setFieldTouched('account_number')}
								disabled
							/>
							<Field
								title="Beneficiary Notes"
								type="textarea"
								placeholder="Enter your text here"
								name="note"
								component={Input}
								onBlur={() => setFieldTouched('note')}
								onKeyUp={(e: any) => {
									if (e.target.value === '') {
										setFieldValue('note', null);
									}
								}}
								disabled
							/>

							<Field
								title="Client Notes"
								type="textarea"
								placeholder=""
								name="client_notes"
								component={Input}
							/>

							<div className="input">
								<p className="input__name">Upload File</p>
								<InputFile fileList={fileList} setFileList={setFileList} />
							</div>
							{/* <div className="input">
								<p className="input__name">Upload File</p>
								<input
									id="upload_file"
									name="upload_file"
									type="file"
									onChange={(event: any) => {
										const fileReader = new FileReader();
										fileReader.onload = () => {
											if (fileReader.readyState === 2) {
												setFieldValue('upload_file', fileReader.result);
											}
										};
										fileReader.readAsDataURL(event.target.files[0]);
										// setFieldValue('upload_file', event.currentTarget.files[0]);
									}}
								/>
							</div> */}
							<div className="input">
								<p className="input__name">Attached Files</p>
								<div className="input__links">
									{formData.files ? (
										formData.files.map((file: any) => {
											return (
												<div>
													<a href={file} target="_blank" rel="noreferrer">
														{file}
													</a>
												</div>
											);
										})
									) : (
										<div> No data</div>
									)}
								</div>
							</div>

							<button
								type="submit"
								className="btn btn-primary btn--full"
								style={{ marginTop: 14 }}
								// disabled={!isValid || !dirty}
								disabled={isSubmitting}
							>
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		</div>
	);
};

export default DetailedViewWithdrawalFiatForm;
