import { FC, useEffect, useState } from 'react';
import { IMessage } from 'redux/reducers/messages/types';
import { handleUploadIcon } from 'layouts-elements/DropFileCustom/config/ImageConfig';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFilesRequest, readMessageRequest } from 'redux/reducers/messages/reducer';
import { getMessages } from 'redux/reducers/messages/selectors';
import ReactHtmlParser from 'react-html-parser';

export interface IAccordion {
	message: IMessage;
}

const Accordion: FC<IAccordion> = ({ message }) => {
	const dispatch = useDispatch();
	const [active, setActive] = useState(false);
	const [read, setRead] = useState(false);
	const data = useSelector(getMessages);

	useEffect(() => {
		setActive(false);
	}, [data?.current_page]);

	useEffect(() => {
		setRead(Boolean(message.pivot.view));
	}, [message]);

	const handleReadMessage = () => {
		if (!read) {
			dispatch(readMessageRequest({ id: message.id }));
			setRead(true);
		}
		setActive(!active);
	};
	return (
		<div className="accordion__item">
			<div className={`accordion__title${active ? ' is-active' : ''}`} onClick={handleReadMessage}>
				<div className="accordion__title-container">
					{!read && (
						<svg
							width="8"
							height="8"
							viewBox="0 0 8 8"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle cx="4" cy="4" r="4" fill="#0071AB" />
						</svg>
					)}
					<p
						className={
							read ? 'accordion__title-text' : 'accordion__title-text accordion__title-unread'
						}
					>
						{message.title}
					</p>
				</div>
				<div className="accordion__title-wrapper">
					<div className="accordion__title-date">
						<p>{moment(moment.utc(message.planned_on)).local().format('DD MMM, YYYY')}</p>
					</div>
					<span className="accordion__title-arrow" />
				</div>
			</div>
			{active && (
				<div className="accordion__content-text">
					{ReactHtmlParser(message.message)}
					{message.files && message.files.length ? (
						<div className="accordion__content-files">
							{message.files.map((item) => (
								<div key={item}>
									<a href={item} target="_blank" rel="noreferrer" download>
										{item}
									</a>
								</div>
							))}
						</div>
					) : null}
				</div>
			)}
		</div>
	);
};

export default Accordion;
