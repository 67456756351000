import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRequest } from 'redux/reducers/auth/reducer';
import { getUserData } from 'redux/reducers/auth/selectors';
import { getTradeHistory } from '../../../redux/reducers/transactions/selectors';
import { getWalletsList } from '../../../redux/reducers/wallets/selectors';
import { capitalize, splitString } from '../../../services/utils/strings';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import { IGetTransactionHistoryRequestPayload } from '../../../redux/reducers/transactions/types';
import { getTradeHistoryRequest } from '../../../redux/reducers/transactions/reducer';
import { getWalletsRequest } from '../../../redux/reducers/wallets/reducer';
import DefaultFields from '../DefaultFields/DefaultFields';
import TradeHistoryDetailsForm from './TradeHistoryDetailsForm';
import {
	getDetailedViewLoading,
	getTradeDetails,
} from '../../../redux/reducers/detailedView/selectors';
import { getTradeDetailsRequest } from '../../../redux/reducers/detailedView/reducer';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';

export interface ITradeHistoryDetails {
	txId: string | number;
}

interface ICustomInput {
	label: string;
	value: string | number;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const TradeHistoryDetails: FC<ITradeHistoryDetails> = ({ txId }) => {
	const dispatch = useDispatch();
	// const tradeHistoryData = useSelector(getTradeHistory)?.data;
	const tradeDetails = useSelector(getTradeDetails);
	const wallets = useSelector(getWalletsList);
	const [currentItemData, setCurrentItemData] = useState<any>(null);
	const userData = useSelector(getUserData);
	const loading = useSelector(getDetailedViewLoading);

	const formatDateTime = (sourceDate: string) => {
		const date = new Date(sourceDate);

		const yyyy = date.getFullYear();
		const mo = String(date.getMonth() + 1).padStart(2, '0');
		const dd = String(date.getDate()).padStart(2, '0');
		const hh = String(date.getHours()).padStart(2, '0');
		const mm = String(date.getMinutes()).padStart(2, '0');
		const ss = String(date.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}:${mm}:${ss}`];
	};

	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}

	const searchAssetName = (id: number) => {
		const search = wallets?.find((el) => el.asset.id === id);
		return search?.asset.name;
	};

	useEffect(() => {
		dispatch(getWalletsRequest());
		dispatch(getUserRequest());
	}, [dispatch]);

	useEffect(() => {
		if (txId) {
			/* const params: IGetTransactionHistoryRequestPayload = {
				apiParams: {
					current_page: 1,
					per_page: 100000000,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					id: Number(txId),
				},
			};
			dispatch(getTradeHistoryRequest(params)); */
			dispatch(getTradeDetailsRequest(Number(txId)));
		}
	}, [dispatch, txId]);

	// useEffect(() => {
	// 	if (tradeHistoryData) {
	// 		setCurrentItemData(tradeHistoryData.filter((item) => String(item.id) === String(txId)));
	// 	}
	// }, [txId, tradeHistoryData]);

	if (tradeDetails && !loading) {
		const [oneText, twoText] = splitText(tradeDetails?.pair || 'btc_eur');
		const [date, time] = formatDateTime(tradeDetails.created_at);
		const idx1 = tradeDetails.side === 'sell' ? 0 : 1;
		const idx2 = tradeDetails.side === 'sell' ? 1 : 0;
		const assetFrom = splitString(tradeDetails.pair, '_')[idx1].toUpperCase();
		const assetTo = splitString(tradeDetails.pair, '_')[idx2].toUpperCase();

		return (
			<div className="detailed-view" key={tradeDetails.id}>
				<div className="input-wrap--col-2">
					{userData && <DefaultFields item={tradeDetails} userData={userData} />}
					<CustomInput
						label="Sell"
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						value={`${capitalize(assetFrom)} ${searchAssetName(tradeDetails.from_asset_id)}`}
						type="text"
						disabled
					/>
					<CustomInput
						label="Buy"
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						value={`${capitalize(assetTo)} ${searchAssetName(tradeDetails.to_asset_id)}`}
						type="text"
						disabled
					/>
					<CustomInput
						label="Amount Sold"
						value={`${roundingNumber(tradeDetails.quantity, assetFrom)} ${capitalize(assetFrom)}`}
						type="text"
						disabled
					/>
					<CustomInput
						label="Amount Received"
						value={`${roundingNumber(tradeDetails.amount, assetTo)} ${capitalize(assetTo)}`}
						type="text"
						disabled
					/>
					{/* <CustomInput
									label="Credit Gross"
									value={`${roundingNumber(item.amount, assetTo)} ${capitalize(assetTo)}`}
									type="text"
									disabled
								/> */}
					<CustomInput
						label="Credit Fee"
						value={`${roundingNumber(tradeDetails.credit_fee, assetTo)} ${capitalize(assetTo)}`}
						type="text"
						disabled
					/>
					{/* <CustomInput label="Credit Net" value="" type="text" disabled /> */}
					{/* <CustomInput label="Debit Gross" value="" type="text" disabled /> */}
					<CustomInput
						label="Debit Fee"
						value={`${roundingNumber(tradeDetails.debit_fee, assetFrom)} ${capitalize(assetFrom)}`}
						type="text"
						disabled
					/>
					{/* <CustomInput label="Debit Net" value="" type="text" disabled /> */}
					<CustomInput
						label="Exchange Rate"
						value={`${toFixedNumber(
							tradeDetails?.price_b2c2 || 0,
							'crypto',
						)} ${twoText.toUpperCase()}`}
						type="text"
						disabled
					/>
					<CustomInput
						label="Trade Fee"
						value={`${roundingNumber(tradeDetails.fee, assetFrom)} ${capitalize(assetFrom)}`}
						type="text"
						disabled
					/>
					<CustomInput label="Tx ID" value={tradeDetails.id} type="text" disabled />
					<CustomInput
						label="B2C2 Trade ID"
						value={tradeDetails.liquidity_provider_id}
						type="text"
						disabled
					/>
				</div>
				<TradeHistoryDetailsForm item={tradeDetails} />
			</div>
		);
	}

	if (!tradeDetails && !loading) {
		return <div>No data.</div>;
	}

	return <div className="text-center">Loading...</div>;
};

export default TradeHistoryDetails;
