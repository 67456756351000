import { http } from 'services/http';
import { endpoint } from 'services/endpoint';

import { IMessageApi } from './types';

export const messages: IMessageApi = {
	getMessages: (payload) =>
		http.get(endpoint.messages.GET_MESSAGES, { params: payload }).then(({ data }) => data),

	downloadFiles: (payload) =>
		http
			.post(endpoint.messages.DOWNLOAD_FILES, payload, { responseType: 'blob' })
			.then(({ data }) => data),
	readMessage: (payload) =>
		http.post(endpoint.messages.READ_MESSAGE, payload).then(({ data }) => data),
};
